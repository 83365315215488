import { FC, useEffect, useMemo, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import classNames from "classnames";
import { capitalize, kebabCase } from "lodash";
import { DateTime } from "luxon";
import queryString from "query-string";

import { useAuth } from "@contexts/auth";
import { useParentContact } from "@hooks/data/clients";
import { useSchedulers } from "@hooks/data/schedulers";
import useChat from "@hooks/use-chat";
import useClientChatStatus from "@hooks/use-client-chat-status";
import useContact from "@hooks/use-contact";
import useEmailSignature from "@hooks/use-email-signature";
import usePackageInstances from "@hooks/use-package-instances";
import { usePackageSchedulers } from "@hooks/use-package-schedulers";
import useScheduler from "@hooks/use-scheduler";
import useSharingModal from "@hooks/use-sharing-modal";
import useSnackbar from "@hooks/use-snackbar";
import analytics from "@lib/analytics";
import { displayNameFromContact } from "@lib/contacts";
import { PackageType } from "@lib/data/schemas/packages";
import { SchedulerType } from "@lib/shared-types";
import { getArtifactLink } from "@lib/utils/artifacts";
import { getCoachEmails } from "@lib/utils/email";
import windowPopup from "@lib/utils/window-popup";

import { Button } from "@components/Button";
import ClientPicker from "@components/ClientPicker";
import { Editor } from "@components/Editor/Editor";
import { SelectAliasesControlled } from "@components/Email/SelectAliases";
import ToggleSignature from "@components/Email/ToggleSignature";
import Label from "@components/Form/Label";
import TextAreaForm from "@components/Form/TextAreaForm";
import TextFieldForm from "@components/Form/TextFieldForm";
import { ToggleSelector } from "@components/Form/ToggleSelector";
import InfoIcon from "@components/Icons/InfoFilledIcon";
import BigModal from "@components/Modals/BigModal";
import GetLinkModal from "@components/Modals/GetLinkModal";
import { getActivePackageInstances } from "@components/Package/AssignPackageSchedulerFormFields";
import PackagePicker from "@components/PackagePicker";
import SchedulerPicker from "@components/SchedulerPicker";

import ClientWarningModal from "./ClientWarningModal";
import { FormFieldsTypes, SharingModalProps } from "./types";
import { getTemplateBody, getTypeSubject } from "./utils";

import "react-quill/dist/quill.bubble.css";

const SharingModal: FC<SharingModalProps> = ({
  show,
  toggleShow,
  client,
  artefactType,
  artefactLink,
  artefactItem,
  schedulerId,
  body = `Here is the ${artefactType} I want to share with you:\n{{link}}`,
  onShare,
  subject,
  expandWithClientId,
  disableClose,
  defaultSendWithPackageId = false,
  showRecipient = false,
}) => {
  const { account, oid, organization, aid } = useAuth();
  const coachEmails = getCoachEmails(account);
  const coachEmail = coachEmails ? coachEmails[0] : undefined;
  const { scheduler } = useScheduler(oid, schedulerId, DateTime.utc());
  const { data: schedulers } = useSchedulers();
  const [selectedPackage, setSelectedPackage] = useState<PackageType | null>(
    null
  );
  const [sendWithPackageId, setSendWithPackageId] = useState<boolean>(
    defaultSendWithPackageId
  );
  const [contactId, setContactId] = useState(client?.id);
  const { contact } = useContact(contactId);
  const { parentContact } = useParentContact(contactId);
  const { contact: recipient } = useContact(
    contact?.primaryContactId ?? contactId
  );

  const { packageInstances } = usePackageInstances(
    oid,
    contactId,
    parentContact?.id
  );
  const { packageSchedulers } = usePackageSchedulers(
    contactId,
    undefined,
    undefined,
    parentContact?.id
  );

  const activePackages = getActivePackageInstances(
    packageInstances ?? [],
    packageSchedulers ?? []
  );

  const showPackageOption =
    !schedulerId && activePackages?.length > 0 && artefactType === "scheduler";

  const filteredSchedulers = useMemo(() => {
    if (showPackageOption && selectedPackage && sendWithPackageId) {
      return packageSchedulers?.filter(
        (scheduler) => scheduler.packageInstanceId === selectedPackage.id
      );
    }
    return schedulers?.filter((scheduler) => {
      const { roundRobin, account, accounts } = scheduler || {};
      if (roundRobin?.roster) return false;
      if (!roundRobin && account) return account.id === aid;
      if (roundRobin && accounts) return accounts.some((a) => a.id === aid);
      return true;
    });
  }, [aid, schedulers, showPackageOption, selectedPackage, sendWithPackageId]);

  const { channel } = useChat(contactId);
  const { withSignature } = useEmailSignature({ userId: oid, memberId: aid });
  const [signatureSelected, setSignatureSelected] =
    useState<boolean>(withSignature);
  const snackbar = useSnackbar();
  const { send, hasEmailIntegrated } = useSharingModal({ coach: account, oid });
  const { clientHasJoined } = useClientChatStatus(contact);

  const [medium, setMedium] = useState(
    hasEmailIntegrated && coachEmail ? coachEmail : "email"
  );
  const [selectedScheduler, setSelectedScheduler] =
    useState<SchedulerType | null>(scheduler);
  const [link, setLink] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showClientWarningModal, setShowClientWarningModal] = useState(false);

  const needsSchedPicker = !schedulerId && artefactType === "scheduler";
  const readOnly = needsSchedPicker && !selectedScheduler;
  const disabled = !contact || readOnly;

  const isArtefactFile = artefactType === "file";
  const isPaymentsArtifact =
    artefactType === "invoice" ||
    artefactType === "product" ||
    (artefactType === "package" && artefactItem?.paymentOptions?.length > 0);
  const isMediumEmail = medium !== "messaging";

  const isMessaging = medium === "messaging";
  const clientName = displayNameFromContact(contact);

  const newSubject =
    isMediumEmail && getTypeSubject(artefactType, artefactItem);

  const shouldAddClientId =
    expandWithClientId && contact && artefactType !== "link";

  const shouldAddPackageId =
    artefactType === "scheduler" && sendWithPackageId && selectedPackage;

  const queryOptions = queryString.stringify({
    ...(shouldAddClientId && { clientId: contactId }),
    ...(shouldAddPackageId && { packageId: selectedPackage.id }),
  });

  const copyLink = artefactLink ? `${artefactLink}?${queryOptions}` : null;

  const getDefaultEmailBodyValue = (message = false) => {
    const link =
      needsSchedPicker && selectedScheduler
        ? `${getArtifactLink(
            organization,
            selectedScheduler,
            "book"
          )}?${queryOptions}`
        : copyLink;

    return getTemplateBody({
      coach: account,
      type: artefactType,
      artifact: needsSchedPicker ? selectedScheduler : artefactItem,
      artifactLink: link,
      bodyOnly: message,
      isMessaging,
    });
  };

  const methods = useForm<FormFieldsTypes>({
    defaultValues: {
      Subject: subject ?? newSubject,
      MessageValue: body && body.replace(/{{link}}/g, artefactLink),
      body: getDefaultEmailBodyValue(),
      fromEmail: hasEmailIntegrated ? coachEmail : medium,
    },
  });
  const {
    handleSubmit,
    formState: { errors, isDirty },
    register,
    setValue,
    control,
  } = methods;

  const handleShare = (fromCopy = false) => {
    if (onShare && contact) {
      if (fromCopy && artefactType === "scheduler") return;
      onShare(contact.id, needsSchedPicker ? selectedScheduler : null);
    }
  };

  useEffect(() => {
    if (needsSchedPicker) {
      setValue("body", getDefaultEmailBodyValue());
      setValue("MessageValue", getDefaultEmailBodyValue(true));
      const tmp = getArtifactLink(organization, selectedScheduler, "book");
      if (tmp) setLink(`${tmp}?${queryOptions}`);
    }
  }, [selectedScheduler]);

  useEffect(() => {
    if (!show) {
      if (selectedScheduler) setSelectedScheduler(null);
      setLink(null);
      setMedium("email");
      setValue("body", getDefaultEmailBodyValue());
    }
  }, [show]);

  useEffect(() => {
    setValue("body", getDefaultEmailBodyValue());
  }, [contact]);

  useEffect(() => {
    if (selectedScheduler) {
      isMessaging
        ? setValue("MessageValue", getDefaultEmailBodyValue(true))
        : setValue("body", getDefaultEmailBodyValue());
    }
    if (isMessaging && !clientHasJoined) setShowClientWarningModal(true);
    setValue(
      "fromEmail",
      medium === "email" && hasEmailIntegrated ? coachEmail : medium
    );
  }, [medium]);

  useEffect(() => {
    if (selectedPackage) {
      const selectedSchedulerIncludedInFiltered = filteredSchedulers?.find(
        (scheduler) => scheduler.id === selectedPackage.schedulerId
      );
      if (!selectedSchedulerIncludedInFiltered) setSelectedScheduler(null);
    }
  }, [selectedPackage, schedulerId, sendWithPackageId, filteredSchedulers]);

  const track = (event: string) =>
    analytics.track({
      event: `${kebabCase(artefactType)}_${event}`,
      userId: oid,
      properties: {
        medium: isMediumEmail ? "email" : medium,
        artefactType: kebabCase(artefactType),
        hasEmailIntegrated,
        withSignature: signatureSelected,
        withEmailAlias: isMediumEmail && medium !== coachEmail,
      },
    });

  const handleFail = (error: Error, message: string) => {
    console.log(error);
    track("shared-error");
    setIsLoading(false);
    toggleShow?.(false);
    snackbar.showMessage("Sharing failed!", message);
  };

  const handleSend = async (data: FormFieldsTypes) => {
    setIsLoading(true);
    if (!isMediumEmail) {
      if (!channel) {
        return handleFail(
          new Error("The channel is not available"),
          "Unable to send the message. Reload the page and try again!"
        );
      }
      try {
        // @TODO: When we finish the `PRD-3769` task we can reinstall the
        //        `node-html-markdown` package and use just the email value
        //        to send the message to the chat message
        // const markdownText = NodeHtmlMarkdown.translate(emailValue);
        await channel.sendMessage({
          text: data.MessageValue,
          silent: true,
          ...(isArtefactFile &&
            artefactItem && {
              data: {
                file: {
                  fileName: artefactItem.fileName,
                  url: artefactItem.url,
                },
              },
            }),
        });
        track("shared");
      } catch (error) {
        return handleFail(
          error,
          "Unable to send the message. Reload the page and try again!"
        );
      }
    } else {
      try {
        await send(
          data,
          (isPaymentsArtifact && contact?.address?.email) || contact?.email
        ); // payments artifacts are sent to the client's billing email if it exist
        track("shared");
      } catch (error) {
        return handleFail(error, "Unable to send the email. Please try again!");
      }
    }
    handleShare();
    setIsLoading(false);
    toggleShow && toggleShow(false);
    snackbar.showMessage(`${capitalize(artefactType)} sent!`);
  };

  const messageSection = isMediumEmail ? (
    <div>
      <Label className="pb-1 mt-4">Message</Label>
      <div
        id="bounds"
        className="rounded-md whitespace-pre-line overflow-visible"
      >
        <Controller
          name="body"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Editor
              className={classNames(
                "sticky-toolbar-t-6",
                readOnly && "bg-grey-950 text-grey-800"
              )}
              readOnly={readOnly}
              kind="email"
              value={value}
              onChange={onChange}
              bounds="#bounds"
            />
          )}
        />
      </div>
    </div>
  ) : (
    <TextAreaForm
      containerClassName="mt-4 h-full"
      disabled={readOnly}
      label="Message"
      name="MessageValue"
      placeholder="Enter your message"
      register={register}
      errors={errors}
      minRows={5}
    />
  );

  const sendOptions = (
    <div className="mt-4 flex flex-col sm:flex-row w-full sm:justify-end">
      <div className="flex-1 flex items-center">
        {hasEmailIntegrated ? (
          <FormProvider {...methods}>
            <ToggleSignature
              className="mb-8 sm:mb-0 mt-4 sm:mt-0"
              onChange={setSignatureSelected}
            />
          </FormProvider>
        ) : (
          <button
            className="flex items-center bg-blue-950 hover:bg-blue-900 rounded-lg text-sm px-4 py-3 font-normal text-black-ink text-left mb-4 sm:mb-0"
            onClick={() => {
              windowPopup({
                url: `/api/gauth/${oid}?view=inbox&scopes=email&accountId=${aid}`,
                name: "gmailAuth",
              });
              track("gmail-integration-start");
            }}
          >
            <InfoIcon className="mr-3 text-blue-500" />
            Want to send with your gmail?{" "}
            <strong className="font-medium ml-2">Tap to connect</strong>
          </button>
        )}
      </div>
      <div className="flex items-center" key={medium === "email" && medium}>
        <FormProvider {...methods}>
          <SelectAliasesControlled
            className="mr-4"
            email={coachEmail}
            prependOptions={[
              artefactType !== "chat invite" && {
                value: "messaging",
                label: "Via client portal",
              },
              !hasEmailIntegrated && { value: "email", label: "Via email" },
            ].filter(Boolean)}
            disabled={disabled}
            onChange={setMedium}
          />
        </FormProvider>
        <Button
          onClick={handleSubmit(handleSend)}
          disabled={disabled || isLoading}
          isLoading={isLoading}
          primary
          className="rounded-lg"
          data-heap-event-name={`shared_${artefactType}_via_${medium}`}
        >
          Send
        </Button>
      </div>
    </div>
  );

  const shareLink = link || copyLink;

  const requiredContactTypeIsChosen = [
    "file",
    "link",
    "invoice",
    "note",
    "folder",
  ].includes(artefactType);

  const copyLinkDisabled = requiredContactTypeIsChosen ? !contactId : false;

  const renderRecipient = showRecipient && recipient && (
    <div className="mb-4">
      <TextFieldForm
        name="recipient"
        label="Recipient"
        defaultValue={displayNameFromContact(recipient) ?? ""}
        disabled
      />
    </div>
  );

  return (
    <>
      <BigModal
        show={show}
        toggleShow={toggleShow}
        title={`Send ${artefactType}${clientName ? ` to ${clientName}` : ""}`}
        disableClose={disableClose}
        isDirty={isDirty}
        secondModalContent={
          shareLink && (
            <GetLinkModal
              disabled={copyLinkDisabled}
              link={shareLink}
              onCopy={() => handleShare(true)}
              type={artefactType}
            />
          )
        }
      >
        <div className="h-full flex flex-col justify-between">
          <div>
            {renderRecipient}
            {showPackageOption && (
              <div className="md:w-1/2 pb-4">
                <ToggleSelector
                  options={[
                    { label: "Scheduler", value: false },
                    { label: "Package", value: true },
                  ]}
                  selected={sendWithPackageId}
                  onChange={(value) => setSendWithPackageId(value)}
                  size="smaller"
                />
              </div>
            )}
            {!client && (
              <ClientPicker
                className="text-left mb-4"
                contactId={contactId}
                handleClientSelection={(contactId: string) =>
                  setContactId(contactId)
                }
                showBillingEmail
              />
            )}
            {showPackageOption && sendWithPackageId && (
              <PackagePicker
                className="mt-0 mb-4"
                packages={activePackages}
                onPackageChange={(packageData) =>
                  setSelectedPackage(packageData)
                }
                selectedPackage={selectedPackage}
              />
            )}
            {needsSchedPicker &&
              filteredSchedulers &&
              (!sendWithPackageId ||
                (sendWithPackageId && selectedPackage)) && (
                <SchedulerPicker
                  className="mt-0 mb-4"
                  schedulers={filteredSchedulers}
                  onSchedulerChange={(scheduler) =>
                    setSelectedScheduler(scheduler)
                  }
                  selectedScheduler={selectedScheduler}
                />
              )}
            <Label className="pb-1">Subject</Label>
            <TextFieldForm
              name="Subject"
              register={register}
              disabled={readOnly || !isMediumEmail}
              placeholder="Enter a subject"
              errors={errors}
              inputClassName={(readOnly || !isMediumEmail) && "text-grey-800"}
              required
            />
            {messageSection}
          </div>
          {sendOptions}
        </div>
      </BigModal>
      <ClientWarningModal
        show={showClientWarningModal}
        toggleShow={(show) => {
          if (!show) setMedium("email");
          setShowClientWarningModal(false);
        }}
        setMedium={setMedium}
        clientName={clientName || "This client"}
      />
    </>
  );
};

export default SharingModal;
