import React, { ReactNode, useEffect, useRef, useState } from "react";
import classNames from "classnames";

export enum DynamicVerticalPosition {
  top = "top",
  bottom = "bottom",
}

type ForcedPositionType =
  | {
      forcePosition: `${DynamicVerticalPosition.top}`;
      verticalTopPosition: string;
      horizontalPositionForTop: string;
      verticalBottomPosition: never;
      horizontalPositionForBottom: never;
    }
  | {
      forcePosition: `${DynamicVerticalPosition.bottom}`;
      verticalBottomPosition: string;
      horizontalPositionForBottom: string;
      verticalTopPosition: never;
      horizontalPositionForTop: never;
    }
  | {
      verticalTopPosition: string;
      verticalBottomPosition: string;
      horizontalPositionForTop: string;
      horizontalPositionForBottom: string;
      forcePosition?: never;
    };

type DynamicPositionProps = {
  children: ReactNode;
  shouldBeVisible: boolean;
} & ForcedPositionType;

export const DynamicPosition: React.FC<DynamicPositionProps> = ({
  children,
  verticalTopPosition,
  verticalBottomPosition,
  horizontalPositionForTop,
  horizontalPositionForBottom,
  forcePosition,
  shouldBeVisible,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<DynamicVerticalPosition>(
    DynamicVerticalPosition.bottom
  );
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const refCurrent = ref.current;

    if (!refCurrent) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.9,
      }
    );

    observer.observe(refCurrent);

    return () => refCurrent && observer.unobserve(refCurrent);
  }, [ref]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (!isVisible && shouldBeVisible) {
      setPosition((prev) =>
        prev === DynamicVerticalPosition.top
          ? DynamicVerticalPosition.bottom
          : DynamicVerticalPosition.top
      );
    }
  }, [ref, isVisible, shouldBeVisible]);

  const positionToSet = forcePosition || position;

  return (
    <div
      className={classNames("absolute z-50 w-max", {
        [`${verticalTopPosition} ${horizontalPositionForTop}`]:
          positionToSet === DynamicVerticalPosition.top,
        [`${verticalBottomPosition} ${horizontalPositionForBottom}`]:
          positionToSet === DynamicVerticalPosition.bottom,
        visible: shouldBeVisible,
        invisible: !shouldBeVisible,
      })}
      ref={ref}
    >
      {children}
    </div>
  );
};
